import 'jquery';
import Swiper from 'swiper';
import Foundation from 'foundation-sites/js/foundation';

Foundation.addToJquery($); // Have to use Foundation for it to be included in webpack bundle

($ => {
    // ----------------------------------------------------------------
    // Initialize Foundation JavaScript
    // ----------------------------------------------------------------

    const foundationInit = () => {
        $(document).foundation();
    };

    // ----------------------------------------------------------------
    // Display Mobile Menu
    // ----------------------------------------------------------------

    const displayMenu = () => {
        const mobileMenuIcon = $('.nav-menu-icon');
        // Mobile menu style toggle
        $(mobileMenuIcon).on('click', () => {
            mobileMenuIcon.toggleClass('active');
            $('body').toggleClass('header-expanded');
        });
    };

    // ----------------------------------------------------------------
    // Initialize Hero Swiper Slider
    // ----------------------------------------------------------------

    const initSlider = () => {
        // eslint-disable-next-line no-unused-vars
        const swiper = new Swiper('.hero-slider', {
            effect: 'fade',
            keyboard: {
                enabled: true
            },
            autoplay: {
                delay: 6000
            },
            spaceBetween: 0,
            speed: 1000,
            a11y: {
                prevSlideMessage: 'Previous slide',
                nextSlideMessage: 'Next slide'
            }
        });
    };

    // ----------------------------------------------------------------
    // Hero Slider Loading Animation
    // ----------------------------------------------------------------
    const loadSlider = () => {
        const slideWrapper = $('.swiper-wrapper');
        const slideOverlay = $('.slide-overlay');
        // const slideText = $('.slide-text');
        const slideTitle = $('.slide-title');
        const slideCopy = $('.slide-copy');
        const slideButton = $('.slide-cta');
        $(slideCopy).fadeTo(500, 1, () => {
            $(slideButton).fadeTo(700, 10);
            // $(slideText).animate({ marginTop: '0' }, 800);
            $(slideTitle).fadeTo(1500, 1);
            $(slideOverlay).css('backgroundColor', 'rgba(0,0,0,0.5)');
            $(slideWrapper).fadeTo(1500, 1);
        });
    };

    // ----------------------------------------------------------------
    // Initialize Events Slider
    // ----------------------------------------------------------------

    const initEventSlider = () => {
        const $windowWidth = $(window).width();
        /* eslint-disable no-nested-ternary */
        const columnsInView =
            $windowWidth < 510
                ? 1
                : $windowWidth < 769
                ? 2
                : $windowWidth < 1025
                ? 5
                : 7;
        // eslint-disable-next-line no-unused-vars
        const eventsSlider = new Swiper('.events-carousel', {
            slidesPerView: 1,
            slidesPerColumn: columnsInView,
            spaceBetween: 40,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });
    };

    // ----------------------------------------------------------------
    // Copy Event Permalink to Clipboard
    // ----------------------------------------------------------------

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str; // Set its value to the string that you copied
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el); // Append the <textarea> element to DOM
        const selected =
            document.getSelection().rangeCount > 0 // Check if there is any content selected previously
                ? document.getSelection().getRangeAt(0) // Store selection if found
                : false; // Mark as false to know no selection existed before
        el.select(); // Select the <textarea> content
        document.execCommand('copy'); // Copy
        document.body.removeChild(el); // Remove the <textarea> element
        if (selected) {
            // If a selection existed before copying
            document.getSelection().removeAllRanges(); // Deselect everything on the HTML document
            document.getSelection().addRange(selected); // Restore original selection
        }
    };

    const getEventLink = () => {
        const shareEventButton = $('.share-event');
        $(shareEventButton).on('click', event => {
            event.preventDefault();
            const eventLink = $(event.target).attr('href');
            copyToClipboard(eventLink);
            const confirmationDialog = $(
                `<div class="dialog-close">✕</div><p class="dialog-title">You've copied this link:</p><p class="dialog-link">${eventLink}</p><p>Paste it into an email, chat window, or other method of communication.</p>`
            );
            $(event.target.nextElementSibling)
                .empty()
                .show()
                .append(confirmationDialog);
            const closeDialogButton = $('.dialog-close');
            $(closeDialogButton).on('click', ev => {
                $(ev.target.parentNode)
                    .empty()
                    .hide();
            });
        });
    };


    // ----------------------------------------------------------------
    // Purchase Form
    // ----------------------------------------------------------------

    const purchaseForm = () => {
        const purchaseEventButton = $('.show-purchase-form');
        $(purchaseEventButton).on('click', event => {
            event.preventDefault();
            $(event.target.nextElementSibling)
                .show();
            const closePurchaseButton = $('.purchase-close');
            $(closePurchaseButton).on('click', ev => {
                $(ev.target.parentNode)
                    .hide();
            });
        });
    };

    // ----------------------------------------------------------------
    // Init
    // ----------------------------------------------------------------

    $(document).ready(() => {
        foundationInit();
        displayMenu();
        getEventLink();
        purchaseForm();
        if ($('.hero-slider').length > 0) {
            initSlider();
            loadSlider();
        }
        if ($('.events-carousel').length > 0) {
            initEventSlider();
        }
    });
    $(window).resize(() => {
        if ($('.hero-slider').length > 0) {
            initSlider();
            loadSlider();
        }
        if ($('.events-carousel').length > 0) {
            initEventSlider();
        }
    });

})(jQuery);
